import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@app/apollo/queries';

export const useUser = () => {
  try {
    const {
      data, loading
    } = useQuery(USER_QUERY);
  
    return {
      loading,
      isAuthenticated: data && data.me,
      user: data?.me,
    }
  } catch (err) {
    return {
      loading: false,
      isAuthenticated: false,
      user: null
    }
  }

};
